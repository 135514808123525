import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import { configuration } from "../../appConfig";
import axios from "axios";
import Swal from "sweetalert2";
import { Cookies } from "react-cookie";
import LanguageContext from "../../context/LanguageProvider";

const cookies = new Cookies();
export class NewOrder extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      Insurance: [],
      insuranceName: "Xc",
      OrderNo: "",
      partnerId: cookies.get("partnerId"),
      isLoading: false,
      error: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectCombo = this.selectCombo.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    let error = [];

    if (error?.length > 0) {
      this.setState({ error });
    } else {
      this.setState({
        error: [],
      });
      this.saveXpertspeedCustomerDetails();
      this.setState({ isLoading: true });
    }
  }
  selectCombo(e) {
    // console.log(this.state.insuranceName,"in")
    this.setState({
      insuranceName: e.target.value,
    });
  }
  componentDidMount() {
    this.getUserSettings();
    this.getInsurenceClients();
  }
  getInsurenceClients = () => {
    let url = configuration.getInsurenceClients;
    axios.get(`${url}?partnerId=${cookies.get("partnerId")}`).then((res) => {
      this.setState({
        Insurance: res.data,
      });
    });
  };
   getUserSettings = () => {
    let url = configuration.generateXpertspeedOrderNumber;
     axios.get(`${url}?partnerId=${cookies.get("partnerId")}`).then((res) => {
      this.setState({
        OrderNo: res.data.order_no,
        
      },()=>cookies.set("vin", this.state.OrderNo));
      
    });
  };

  saveXpertspeedCustomerDetails = () => {
    let payload = {
      VIN: this.state.OrderNo,
      insuranceName: this.state.insuranceName,
      partnerId: this.state.partnerId,
    };
    let url = configuration.saveXpertspeedCustomerDetails;
    axios.post(url, payload).then((res) => {
      console.log(res, "response");
      if (res.data.success === true) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Save SucessFully",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({ isLoading: false });
        this.props.router.navigate("/vihicalrcbook");
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Data Not Updated",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({ isLoading: false });
      }
    });
  };
  render() {
    console.log(this.state.Insurance);
    const { error, isLoading } = this.state;
    const { lang } = this.context;
    return (
      <div className="general-information-main">
        <div className="row">
          <div className="col-12">
            <form onSubmit={this.handleSubmit}>
              <div className="card">
                
                <div className="card-body">
                  <div className="login-form">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="custom-input">
                          <label>{lang("OrderNr.")}</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name={lang("OrderNr.")}
                              value={this.state.OrderNo}
                              onChange={this.handleChange}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="custom-input selectdiv">
                          <label>{lang("Insurance")}</label>
                          <div className="input-group">
                            <select
                              className="form-control"
                              onChange={this.selectCombo}
                            >
                              <option value="Xc">Self</option>
                              {Object.keys(this.state.Insurance).map(
                                (key, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={this.state.Insurance[key].Name}
                                    >
                                      {this.state.Insurance[key].Name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="next-btn">
                <button
                  className="btn orange-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading && (
                    <i
                      className="fa fa-circle-o-notch fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {isLoading}
                  {!isLoading && <span>{lang("Next")}</span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewOrder);
