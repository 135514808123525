import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import { configuration } from "../../appConfig";
import axios from "axios";
import { Cookies } from "react-cookie";
import Swal from "sweetalert2";
import LanguageContext from "../../context/LanguageProvider";

const cookies = new Cookies();
export class UserSettings extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      carrossier: "",
      company_name: "",
      contact_number: "",
      email: "",
      lang: "",
      mechaniker: "",
      name: "",
      small_material_amount: "",
      partnerId: "",
      spengler: "",
      selectCombo:"Male", 
      error: [],
      isLoading: false,
      insurance: '',
      insuranceArr: [
        {
          insurance: " Die Mobiliar",
          carrossier: 145,
          Plumbers: 135,
          Mechanic: 145,
        },
        {
          insurance: "Emmenthaler",
          carrossier: 145,
          Plumbers: 135,
          Mechanic: 145,
        },
        {
          insurance: "Zurich",
          carrossier: 145,
          Plumbers: 135,
          Mechanic: 145,
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectCombo = this.selectCombo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  selectCombo(e) {
    this.setState({
      selectCombo: e.target.value,
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  componentDidMount() {
    this.getUserSettings();
  }

  getUserSettings = () => {
    let url = configuration.getXpertspeedSetting;
    axios.get(`${url}?partnerId=${cookies.get("partnerId")}`).then((res) => {
      this.setState({
        address: res.data.address,
        carrossier: res.data.carrossier,
        company_name: res.data.company_name,
        contact_number: res.data.contact_number,
        email: res.data.email,
        lang: res.data.lang,
        machanic: res.data.mechaniker,
        name: res.data.name,
        small_material_amount: res.data.small_material_amount,
        partnerId: res.data.partnerId,
        spengler: res.data.spengler,
      });
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.UpdatedUserSettings();
    this.setState({ isLoading: true });
  }
  UpdatedUserSettings = () => {
    let payload = {
      address: this.state.address,
      carrossier: this.state.carrossier,
      company_name: this.state.company_name,
      contact_number: this.state.contact_number,
      email: this.state.email,
      lang: this.state.lang,
      machanic: this.state.mechaniker,
      name: this.state.name,
      small_material_amount: this.state.small_material_amount,
      partnerId: this.state.partnerId,
      spengler: this.state.spengler,
    };
    let url = configuration.createXpertspeedSetting;
    axios.post(url, payload).then((res) => {
      console.log(res, "response");
      if (res.data.success === "true") {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Update SucessFully",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({ isLoading: false });
        this.props.router.navigate(-1);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Data Not Updated",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({ isLoading: false });
      }
    });
  };

  handleModalChange = (e) => {
    const {name,value} = e.target;
    this.setState({
      [name]: value
    })
  }

  handleAdd = () => {
    const {Plumbers, carrossier, insurance,Mechanic,insuranceArr} = this.state;
    let obj = {
      Plumbers: Plumbers || 135, carrossier: carrossier || 145 , insurance,Mechanic : Mechanic || 145 
    }
    let arr = [...insuranceArr,obj]
    this.setState({
      insuranceArr : arr
    });
  }

  render() {
    const { isLoading, Plumbers, carrossier, insurance,Mechanic, insuranceArr } = this.state;
    const { lang } = this.context;
    return (
      <>
        <div className="user-settings-main">
          <div className="row">
            <div className="col-12">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className="card-header">
                    <div className="title-header">
                      <h5 className="small-title-bold"> {lang("usersettings")}</h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="users-settings-form">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("Companyname")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={lang("Companyname")}
                                name="company_name"
                                value={this.state.company_name}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("Customer Nr.")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={lang("Customer Nr.")}
                                name="customer_number"
                                value={this.state.customer_number}
                                defaultValue="12345"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="custom-input selectdiv">
                            <label >{lang("Gender")}</label>
                            <div className="input-group">
                              <select className="form-control"  onChange={this.selectCombo}>
                                <option value="Male">{lang("Male")}</option>
                                <option value="Female">{lang("Female")} </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("Surname")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={lang("Surname")}
                                name="surname"
                                value={this.state.surname}
                                defaultValue="Muster"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("Name")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={lang("Name")}
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("Street")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={lang("Street")}
                                name="street"
                                value={this.state.address}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("E-Mail")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={lang("E-Mail")}
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("Mobilenr")}</label>
                            <div className="input-group">
                              <input
                                type="tel"
                                className="form-control"
                                placeholder={lang("Mobilenr")}
                                value={this.state.contact_number}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("Number")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nr"
                                defaultValue="+41 (0)31 389 89 58"
                                value={this.state.Nr}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("Postcode")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={lang("Postcode")}
                                value={this.state.PLZ}
                                defaultValue="3011"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                          <div className="custom-input">
                            <label >{lang("Location")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={lang("Location")}
                                value={this.state.ort}
                                defaultValue="Bern"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="user-settings-tab">
                            <div className="row">
                              <div className="col-lg-11">
                                <ul
                                  className="nav nav-tabs"
                                  id="myTab"
                                  role="tablist"
                                >
                                  {insuranceArr.map((obj,i) => (
                                    <li className="nav-item" role="presentation">
                                      <a
                                        className={`nav-link ${i === 0 ? 'active' : ""}`}
                                        id="insurence-one-tab"
                                        data-toggle="tab"
                                        href={`#insurence_${i}`}
                                        role="tab"
                                        aria-controls={`insurence_${i}`}
                                        aria-selected="true"
                                      >
                                      {obj.insurance}
                                    </a>
                                  </li>
                                  ))}
                                  
                                </ul>
                              </div>
                              <div className="col-lg-1">
                                <div className="add-tab-button">
                                  <button
                                    type="button"
                                    className="btn"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="tab-content" id="myTabContent">
                              {insuranceArr.map((obj,i) => {
                                return<div
                                className={`tab-pane ${i === 0 ? 'show active' : ''} fade`}
                                  id={`insurence_${i}`}
                                  role="tabpanel"
                                  aria-labelledby="insurence-one-tab"
                                >
                                  <div className="user-tab-content">
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="user-tab-title">
                                          <h5>{lang("HourlyRates")}</h5>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                        <div className="custom-input">
                                          <div className="user-tab-input-text-top">
                                            <div className="user-tab-input">
                                              <label >{lang("Mechanic")}</label>
                                              <div className="input-group">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="machanic"
                                                  value={obj.Mechanic}
                                                  onChange={this.handleChange}
                                                />
                                              </div>
                                            </div>
                                            <div className="input-side-text">
                                              <span className="extra-small-text-black">
                                               {lang("CHF/Std")}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                        <div className="custom-input">
                                          <div className="user-tab-input-text-top">
                                            <div className="user-tab-input">
                                              <label >{lang("Plumber")}</label>
                                              <div className="input-group">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={lang("Plumber")}
                                                  value={obj.Plumbers}
                                                  onChange={this.handleChange}
                                                />
                                              </div>
                                            </div>
                                            <div className="input-side-text">
                                              <span className="extra-small-text-black">
                                                {lang("CHF/Std")}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                        <div className="custom-input">
                                          <div className="user-tab-input-text-top">
                                            <div className="user-tab-input">
                                              <label >{lang("Carrossorie")}</label>
                                              <div className="input-group">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Carrossier"
                                                  value={obj.carrossier}
                                                  onChange={this.handleChange}
                                                />
                                              </div>
                                            </div>
                                            <div className="input-side-text">
                                              <span className="extra-small-text-black">
                                              {lang("CHF/Std")}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <div className="custom-input">
                                          <div className="user-tab-input-text-top">
                                            <div className="user-tab-input">
                                              <label ></label>
                                              <div className="input-group">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={lang("%oftotalforsmallmaterial")}
                                                  value={
                                                    this.state.small_meterial || 10
                                                  }
                                                  onChange={this.handleChange}
                                                />
                                              </div>
                                            </div>
                                            <div className="input-side-text">
                                              <span className="extra-small-text-black">
                                                 {lang("%oftotalforsmallmaterial")}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              })}
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="next-btn">
                <button
                    type="submit"
                    disabled={isLoading}
                    className="btn orange-btn"
                  >
                    {isLoading && (
                      <i
                        className="fa fa-circle-o-notch fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {isLoading}
                    {!isLoading && <span>{lang("Next")}</span>}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="modal fade custom-modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                   
                    <div className="modal-body">
                        <div className="user-tab-content">
                            <div className="row">
                                <div className="col-12">
                                    <div className="user-tab-title">
                                        <h5>{lang("HourlyRates")}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="custom-input">
                                        <div
                                            className="user-tab-input-text-top">
                                            <div className="user-tab-input">
                                                <label
                                                    >{lang("InsuranceName")}</label>
                                                <div className="input-group">
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder={lang("InsuranceName")}
                                                        name="insurance"
                                                        value={insurance}
                                                        onChange={(e) => this.handleModalChange(e)}
                                                      />
                                                </div>
                                            </div>
                                            <div className="input-side-text">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="custom-input">
                                        <div
                                            className="user-tab-input-text-top">
                                            <div className="user-tab-input">
                                                <label
                                                    >{lang("Mechanic")}</label>
                                                <div className="input-group">
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder={lang("Mechanic")}
                                                        // value="145" 
                                                        name="Mechanic"
                                                        value={Mechanic || 145}
                                                        onChange={(e) => this.handleModalChange(e)}
                                                      />
                                                </div>
                                            </div>
                                            <div className="input-side-text">
                                                <span
                                                    className="extra-small-text-black">CHP/Std.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="custom-input">
                                        <div
                                            className="user-tab-input-text-top">
                                            <div className="user-tab-input">
                                                <label
                                                    >{lang("Plumber")}</label>
                                                <div className="input-group">
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder={lang("Plumber")}
                                                        // value="135" 
                                                        name="Plumbers"
                                                        value={Plumbers || 135}
                                                        onChange={(e) => this.handleModalChange(e)}  
                                                      />
                                                </div>
                                            </div>
                                            <div className="input-side-text">
                                                <span
                                                    className="extra-small-text-black">CHP/Std.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="custom-input">
                                        <div
                                            className="user-tab-input-text-top">
                                            <div className="user-tab-input">
                                                <label
                                                    >{lang("Carrossorie")}</label>
                                                <div className="input-group">
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder={lang("Carrossorie")}
                                                        value={carrossier || "125"}
                                                        name="carrossier"
                                                        onChange={(e) => this.handleModalChange(e)}  
                                                      />
                                                </div>
                                            </div>
                                            <div className="input-side-text">
                                                <span
                                                    className="extra-small-text-black"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="custom-input">
                                        <div
                                            className="user-tab-input-text-top">
                                            <div className="user-tab-input">
                                                <label
                                                    ></label>
                                                <div className="input-group">
                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder="Carrossier"
                                                        value="10" 
                                                      />
                                                </div>
                                            </div>
                                            <div className="input-side-text">
                                                <span
                                                    className="extra-small-text-black">{lang("%oftotalforsmallmaterial")}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn gray-btn" data-dismiss="modal">Close</button>
                        <button type="button" className="btn orange-btn" onClick={() => this.handleAdd()}>{lang("Next")}</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </>
    );
  }
}

export default withRouter(UserSettings);
