import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import Swal from "sweetalert2";
import LanguageContext from "../../context/LanguageProvider";

export class SuccessMessage extends Component {
  static contextType = LanguageContext;
  handleClick = () => {
    Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Damage Part Image Upload SucessFully",
        showConfirmButton: false,
        timer: 1500,
      });
    this.props.router.navigate("/neworder");
  };
  render() {
    const { lang } = this.context;
    return (
      <div>
        <div className="vehicle-content-main">
          <div className="row">
            <div className="col-12">
              <form action="">
                <div className="card">
                  <div className="card-body">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="heder-title">
                          <h6 className="second-small-title-bold text-center">
                            {lang("WE NOW HAVE ALL THE DATA TO PERFORM THE DAMAGE CALCULATION. TO COMPLETE THE PROCESS, PLEASE CLICK ON THE 'SUBMIT CLAIM' BUTTON.")}
                          </h6>
                        </div>
                        <div className="vehicle-inner-content message-content">
                          <p className="small-text-black">
                            {lang("The damage will then be assessed by an insurance expert. As soon as the decision has been made, you will receive an SMS on your mobile with a link to the damage calculation.")}
                          </p>
                          <p className="small-text-black">
                            {lang("Thank you for your cooperation.")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="next-btn">
                  <a className="btn orange-btn" onClick={this.handleClick}>
                    {lang("TRANSMIT DAMAGE")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(SuccessMessage);
