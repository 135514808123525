import axios from "axios";
import React, { Component } from "react";
import { createContext } from "react";
import { configuration } from "../appConfig";
import { Cookies } from "react-cookie";

const cookies = new Cookies();
// import toastAlert from '../helper/ToastAlert/ToastAlert';
const LanguageContext = createContext({});

class LanguageProvider extends Component {
  constructor() {
    super();
    this.state = {
      lang: null,
      words: {},
    };
  }

  componentDidMount = () => {
    this.setState({
      lang:"CH-DE",
    });
    this.getLang();
  };

  getLang = async () => {
    // let url = configuration.lang;
    let baseUrl = window.location.origin;
    let url = `${baseUrl}/lang.json`;
    console.log(url,"urlll")
    axios
      .get(url)
      .then((res) => {
        console.log(res,"res")
        if (res?.data) {
          this.setState({
            words: res.data,
          });
        }
        console.log("API CALLED");
      })
      .catch((err) => {
        // toastAlert(
        //   err?.response?.data?.message
        //     ? err.response.data.message
        //     : "Something went wrong!",
        //   "error"
        // );
        console.log(err, "err");
      });
  };

  lang = (word) => {
    const { lang, words } = this.state;
    // console.log()
    return words && words[word] && words[word][lang]
      ? words[word][lang]
      :  word;
  };

  userLanguageChange = (lang) => {
    this.setState({
      lang: lang,
    });
    cookies.set("lang", lang);
  };

  render() {
    const { lang } = this.state;
    return (
      <LanguageContext.Provider
        value={{
          userLanguageChange: this.userLanguageChange,
          lang: this.lang,
          language: lang,
        }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

export default LanguageContext;
export { LanguageProvider };
