import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import LanguageContext from "../../context/LanguageProvider";

export class SelectLanguage extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
  }

  handleLanguageClick(lang) {
    this.context.userLanguageChange(lang);
    this.props.router.navigate(-1);
  }

  render() {
    return (
      <div className="select-country-main">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="title-header">
                  <h5 className="small-title-bold">
                    SPRACHE/ LANGUE/ LINGUA/ LANGUAGE
                  </h5>
                </div>
              </div>
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="language-main">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="language-img">
                            <a onClick={() => this.handleLanguageClick("CH-DE")}>
                              <img
                                src="assets/images/image-4.png"
                                alt="DEUTSCH"
                              />
                              <h6 className="blue-text">DEUTSCH</h6>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="language-img">
                            <a onClick={() => this.handleLanguageClick("CH-FR")}>
                              <img
                                src="assets/images/image-5.png"
                                alt="FRANCAIS"
                              />
                              <h6 className="blue-text">FRANCAIS</h6>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="language-img">
                            <a onClick={() => this.handleLanguageClick("CH-IT")}>
                              <img
                                src="assets/images/italy 1.png"
                                alt="ITALY"
                              />
                              <h6 className="blue-text">ITALY</h6>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SelectLanguage);
