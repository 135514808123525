
import React, { Component } from "react";
import { Navigate, Route } from "react-router-dom";
import { Cookies } from "react-cookie";
import { withRouter } from "../../withRouter";
import Header from "../../page/header/Header";
const cookies = new Cookies();

export class PrivateRoute extends Component {
  render() {
    const { children } = this.props;
    const isAuthenticated = cookies.get("success") === "true" ? true : false;
    
    return isAuthenticated ? (
      <>
        <main className="all-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-sm-12 main-col">
                <Header />
                {children}
              </div>
            </div>
          </div>
        </main>
      </>
    ) : (
      <Navigate to="/" />
    );
  }
}

export default withRouter(PrivateRoute);
// const PrivateRoute = ({ component: Component}) => {
//   return (
//     <Route
//       render={(props) =>
//         cookies.get("success") ? (
//           <Component {...props} />
//         ) : (
//           this.props.router.navigate("/")
//         )
//       }
//     />
//   );
// };
