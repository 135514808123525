import React, { Component } from "react";
import LanguageContext from "../../context/LanguageProvider";
import { Cookies } from "react-cookie";
import { configuration } from "../../appConfig";
import Swal from "sweetalert2";
import axios from "axios";
import { withRouter } from "../../withRouter";

const data = [
  { id: 1, name: "mileage" },
  { id: 2, name: "chassisNumber" },
  { id: 3, name: "frontLeft" },
  { id: 4, name: "frontRight" },
  { id: 5, name: "backRight" },
  { id: 6, name: "backLeft" },
  { id: 7, name: "damageOverview-1" },
  { id: 8, name: "damageOverview-2" },
  { id: 9, name: "damageOverview-3" },
  { id: 10, name: "detailedDamage-1" },
  { id: 11, name: "detailedDamage-2" },
  { id: 12, name: "detailedDamage-3" },
  { id: 13, name: "preDamage-1" },
  { id: 14, name: "preDamage-2" },
  { id: 15, name: "preDamage-3" },
];

const cookies = new Cookies();
export class DamageRecording extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      partnerId: cookies.get("partnerId"),
      partName: "",
      color: "blacks",
      imageArr: {},
    };
  }


  handleFileChange = (e) => {
    const { name } = e.target;
    this.setState({ partName: name.includes("/") ? name.split("/")[1] : name });
    console.log("qqqqqqq");
    let files = e.target.files;
    console.log(files, "files");
    let fileReader = new FileReader();
    console.log(fileReader, "fileReader");
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (e) => {
      this.setState(
        {
          image: e.target.result,
        },
        () => this.uploadXpertspeedPartImg(files[0])
      );
    };
  };

  componentDidMount() {
    this.getUserSettings();
  }

  uploadXpertspeedPartImg = (file) => {
    let data = new FormData();
    data.append("partnerId", cookies.get("partnerId"));
    data.append("partName", this.state.partName);
    data.append("img", file);
    data.append("vin", cookies.get("vin"));
    let url = configuration.uploadDamageRecordingImg;
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Damage Part Image Upload SucessFully",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.props.router.navigate("/successmessage");
          this.setState({
            isLoading: false,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Something went wrong. Please try again later",
            showConfirmButton: false,
            timer: 1500,
          });
          this.setState({ isLoading: false });
        }
      }, [])
      .then(() => this.getUserSettings());
  };

  getUserSettings = () => {
    let url = configuration.getDamageRecordingImg;
    axios
      .get(
        `${url}?vin=${cookies.get("vin")}&partnerId=${cookies.get("partnerId")}`
      )
      .then((res) =>
        this.setState({
          imageArr: res.data,
          chassisNumber: res.data.chassisNumber,
          "damageOverview-1": res.data["damageOverview-1"],
          "damageOverview-2": res.data["damageOverview-2"],
          "damageOverview-3": res.data["damageOverview-3"],
          "detailedDamage-1": res.data["detailedDamage-1"],
          "detailedDamage-2": res.data["detailedDamage-2"],
          "detailedDamage-3": res.data["detailedDamage-3"],
          mileage: res.data.mileage,
          frontLeft: res.data.frontLeft,
          frontRight: res.data.frontRight,
          backLeft: res.data.backLeft,
          backRight: res.data.backRight,
          "preDamage-1": res.data["preDamage-1"],
          "preDamage-2": res.data["preDamage-2"],
          "preDamage-3": res.data["preDamage-3"],
        })
      );
    //  Url.php?partnerId='xyz'&vin='123'
  };
  render() {
    const { lang } = this.context;
    return (
      <div>
        <div className="damage-recording-wrapper">
          <div className="row">
            <div className="col-12">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="damage-recording-row-top">
                        <div className="row">
                          {data.map(({ name, id }) => {
                            return (
                              <div
                                key={id}
                                className="col-lg-4 col-md-4 col-sm-6 col-6 damage-card-col uploaded-red-image"
                              >
                                <div className="damage-camera-main">
                                  <div className="damage-camera-img">
                                    <div className="upload-btn-wrapper">
                                      <div>
                                        <div className="vehicle-image-upload">
                                          <img
                                            src={
                                              Object.keys(this.state.imageArr).length &&
                                              Object.keys(this.state.imageArr[name]).length
                                                ? "assets/images/red-camera.svg"
                                                : "assets/images/black-camera.svg"
                                            }
                                            alt="Damage Camera"
                                          />
                                        </div>
                                        <input
                                          type="file"
                                          name={name}
                                          onChange={(e) =>
                                            this.handleFileChange(e)
                                          }
                                        />
                                      </div>
                                      <div className="damage-camera-content">
                                        <p className="small-text-black">
                                          {lang(name)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="next-btn">
                  <button className="btn orange-btn" onClick={()=> this.props.router.navigate("/successmessage")}>{lang("Next")}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DamageRecording);
