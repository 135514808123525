import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../auth/Login";
import SelectLanguage from "../languageSelect/SelectLanguage";
import VehicalLicence from "../vehicle-licence/VehicalLicence";
import PrivateRoute from "./PrivateRoute";
import UserSettings from "../user-settings/UserSettings";
import SuccessMessage from "../success-message/SuccessMessage";
import NewOrder from "../neworder/NewOrder";
import DamageRecording from "../damageRecording/DamageRecording";

export default class FormRoutes extends Component {
  render() {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/selectLanguage" Component={SelectLanguage} />
          <Route
            path="/neworder"
            element={
              <PrivateRoute>
                <NewOrder />
              </PrivateRoute>
            }
          />
          <Route
            path="/vihicalrcbook"
            element={
              <PrivateRoute>
                <VehicalLicence />
              </PrivateRoute>
            }
          />
          <Route
            path="/UserSettings"
            element={
              <PrivateRoute>
                <UserSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/damagerecording"
            element={
              <PrivateRoute>
                <DamageRecording />
              </PrivateRoute>
            }
          />
          <Route
            path="/successmessage"
            element={
              <PrivateRoute>
                <SuccessMessage />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    );
  }
}
