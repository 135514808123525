let url = "https://drivenscan.com/api_v2/xpertspeed";

export const configuration = {
  userInterfaceLogin: url + "/userInterfaceLogin.php",
  generateXpertspeedOrderNumber: url + "/generateXpertspeedOrderNumber.php",
  getInsurenceClients: url + "/getInsurenceClients.php",
  saveXpertspeedCustomerDetails: url + "/saveXpertspeedCustomerDetails.php",
  saveVehicleRegDoc: url + "/saveVehicleRegDoc.php",
  uploadDamageRecordingImg: url + "/uploadDamageRecordingImg.php",
  getDamageRecordingImg: url + "/getDamageRecordingImg.php",
  getXpertspeedSetting : url + '/getXpertspeedSetting.php',
  createXpertspeedSetting : url + '/createXpertspeedSetting.php',
  // carFrontSide : url + '/saveVehicleRegDoc.php',
  // saveXpertspeedCustomerDetails : url + '/saveXpertspeedCustomerDetails.php',
  // uploadXpertspeedPartImg : url + '/uploadXpertspeedPartImg.php',
  // uploadXpertspeedPartImg : url + '/uploadXpertspeedPartImg.php',
};
