import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import Swal from "sweetalert2";
import { Cookies } from "react-cookie";
import { configuration } from "../../appConfig";
import axios from "axios";
import LanguageContext from "../../context/LanguageProvider";

const cookies = new Cookies();
export class VehicalLicence extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      partnerId: cookies.get("partnerId"),
      vin: cookies.get("vin"),
      image: "",
      partName: "vehicleRegistrationDocument",
      error: [],
      isLoading: false,
    };
    this.handleImageSelect = this.handleImageSelect.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleImageSelect(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      this.setState({
        image: event.target.result,
      });
    };
  }

  saveVehicleRegDoc = () => {

    let payload = {
      partnerId: this.state.partnerId,
      partName: this.state.partName,
      base64image: this.state.image,
      vin: this.state.vin,
    };
    let url = configuration.saveVehicleRegDoc;
    axios.post(url, payload).then((res) => {
      console.log(res, "response");
      console.log(res.data.success, "res.ok === true");
      if (res.data.success) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Image Upload SuccessFully",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({ isLoading: false });
        this.props.router.navigate("/damagerecording", {
          state: {
            uploadFrontImage: false,
            uploadBackImage: false,
          },
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong. Please try again later",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({ isLoading: false });
      }
    });
  };
  handleClick(e) {
    this.saveVehicleRegDoc();
    this.setState({ isLoading: true });
  }

  render() {
    const { isLoading } = this.state;
    const { lang } = this.context;
    return (
      <>
        <div className="heder-title text-center">
            <h3 className="header-title-bold">{lang('photoYours')}</h3>
            <h3 className="header-title-bold">{lang('vehicleRegistrationCard')}</h3>
        </div>
        <div className="vehicle-content-main">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div className="vehicle-inner-content">
                        <p className="small-text">
                          {lang(
                            "Click the blue 'Take a picture' button below and the camera of your smartphone or tablet will open automatically. You can then take the corresponding photo. Finally, click the 'Save & Continue' button at the bottom of the page to proceed to the next step."
                          )}
                        </p>
                      </div>
                      <div className="license-upload">
                        <div className="vehicle-image-upload">
                          <img
                            src="assets/images/vehicle-license.png"
                            alt="vehicle-license"
                          />
                        </div>
                        <div className="image-upload-main vehicle-license-upload">
                          <div className="upload-btn-wrapper">
                            <div>
                              <div className="camera-icon">
                                <i className="fa fa-camera"></i>
                              </div>
                            </div>
                            <input
                              onChange={this.handleImageSelect}
                              type="file"
                              name="image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="vehicle-inner-content">
                        <h5 className="gray-text">
                          {lang("How do you get the best result?")}
                        </h5>
                        <p className="small-text">
                          {lang(
                            "Photograph in landscape format (Landscape) and make sure that the vehicle registration card on the left and right exactly matches the"
                          )}
                        </p>
                      </div>
                      <div className="uploaded-image">
                        <h6 className="blue-text">
                          {lang("Vehicle registration")}:
                        </h6>
                        <div className="uploaded-vehicle-license">
                          <img src={this.state.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="next-btn">
                <a className="btn orange-btn" onClick={this.handleClick}>
                {isLoading && (
                      <i
                        className="fa fa-circle-o-notch fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {isLoading}
                    {!isLoading && <span>{lang("Next")}</span>}
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(VehicalLicence);
