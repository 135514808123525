import React, { Component } from "react";
import { withRouter } from "../../withRouter";
import { Cookies } from "react-cookie";
import LanguageContext from "../../context/LanguageProvider";

const cookies = new Cookies();
let langArr = [
  {
    icon: "de",
    lang: "DE",
  },
  {
    icon: "en",
    lang: "US",
  },
  {
    icon: "en",
    lang: "US",
  },
  {
    icon: "en",
    lang: "US",
  },
];  
export class Header extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);

    this.handleUserSetting = this.handleUserSetting.bind(this);
    this.LogOut = this.LogOut.bind(this);
  }
  handleClick = (lang) => {
    const { userLanguageChange } = this.context;
    userLanguageChange(lang);
    this.setState({ selectedLanguage: lang });
}
  handleUserSetting(e) {
    e.preventDefault();
    this.props.router.navigate("/UserSettings");
  }
  LogOut(e) {
    e.preventDefault();
    cookies.remove("success");
    cookies.remove("partnerId");
    cookies.remove("vin");
    cookies.remove("uploadFrontImage");
    cookies.remove("uploadBackImage");
    cookies.remove("lang");
    this.props.router.navigate("/");
  }

  handleLanguageSelect=()=>{
    this.props.router.navigate("/selectLanguage");
  }

  render() {
    return (
      <div className="logo-header-main">
        <div className="row">
          <div className="col-lg-12">
            <div className="settings-lang-main">
              <div className="header-settings">
                <div className="profile-dropdown">
                  <div className="dropdown">
                    <button
                      className="btn orange-btn profile-btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-user"></i>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item"
                        onClick={this.handleUserSetting}
                      >
                        User settings
                      </a>
                      <a className="dropdown-item" onClick={this.LogOut}>
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
                <a className="btn orange-btn select-language" onClick={this.handleLanguageSelect}>
                  <i className="fa fa-globe"></i> Language Select
                 
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 header-logo-col">
            <a className="header-logo">
              <img
                src="assets/images/expertCenter.svg"
                alt="ExpertCenter-Logo"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
