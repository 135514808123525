import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "../../withRouter";
import Swal from "sweetalert2";
import { configuration } from "../../appConfig";
import { Cookies } from "react-cookie";
import LanguageContext from "../../context/LanguageProvider";

// url= http://127.0.0.1:8000/api/log-in
// Email: "xclaims-xc@adi-ch.com",
// password: "adi12345#",
const cookies = new Cookies();
export class Login extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
      password: "",
      error: [],
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    let error = [];
    if (!this.state.Email?.trim()) {
      error.push("Email");
    }
    if (!this.state.password?.trim()) {
      error.push("password");
    }

    if (error?.length > 0) {
      this.setState({ error });
    } else {
      this.setState({
        error: [],
      });
      this.ApiPost();
      this.setState({ isLoading: true });
    }
  }

  ApiPost = () => {
    let payload = {
      username: this.state.Email,
      password: this.state.password,
    };
    let url = configuration.userInterfaceLogin;
    axios.post(url, payload).then((res) => {
      console.log(res, "response");
      console.log(res.data.success, "res.ok === true");
      if (res.data.success === "true") {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Login Success Fully",
          showConfirmButton: false,
          timer: 1500,
        });
        cookies.set("partnerId", res.data.partnerId);
        cookies.set("success", res.data.success);
        // localStorage.setItem("partnerId", JSON.stringify(res.data.partnerId));
        this.setState({ isLoading: false });
        this.props.router.navigate("/neworder");
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Login Failed",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { error, isLoading } = this.state;
    const { lang } = this.context;
    return (
      <div>
        <main className="all-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-sm-12 main-col">
                <div className="logo-header-main">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="col-12 header-logo-col">
                        <div className="header-logo">
                          <img
                            src="assets/images/expertCenter.svg"
                            alt="ExpertCenter-Logo"
                          />
                        </div>
                        <div className="heder-title">
                          <h3 className="header-title-light">XpertSpeed</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="login-content-main">
                  <div className="row justify-content-center">
                    <div className="col-lg-11 col-md-12 col-sm-12">
                      <form onSubmit={this.handleSubmit}>
                        <div className="card">
                          <div className="card-header">
                            <div className="title-header">
                              <h5 className="small-title-bold">
                                {lang("Registration")}
                              </h5>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="login-form">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="custom-input">
                                    <label>
                                      {lang("Username")}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        name="Email"
                                        className="form-control"
                                        placeholder={lang("Username")}
                                        aria-describedby="basic-addon1"
                                        value={this.state.Email}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    {error.includes("Email") && (
                                      <span style={{ color: "red" }}>
                                        {lang("User name is required!")}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="custom-input">
                                    <label>
                                      {lang("Password")}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="password"
                                        className="form-control"
                                        placeholder={lang("Password")}
                                        name="password"
                                        aria-describedby="basic-addon1"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    {error.includes("password") && (
                                      <span style={{ color: "red" }}>
                                        {lang("Passwords is required!")}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="next-btn">
                          <button
                            type="submit"
                            disabled={isLoading}
                            className="btn orange-btn"
                          >
                            {isLoading && (
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            )}
                            {isLoading}
                            {!isLoading && <span>{lang("Next")}</span>}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(Login);
